import React, { useEffect, useState } from 'react'
import CustomButton from '../../../componets/Button'
import { ArrowLeft01Icon, ArrowRight01Icon, Delete01Icon, EyeIcon, PencilEdit01Icon, PlusSignIcon, Search02Icon, SearchingIcon, SquareIcon } from 'hugeicons-react'

import '../../../assets/scss/articles/articles.css'
import PageContainer from '../PageContainer'
import { articles } from '../../../constants/articles'
import api from '../../../services/api'
import { formatDateTime } from '../../../services/formatDateTime'
import { handleSearchByTitle } from '../../../services/handleSearch'
import LoadingIcon from '../../../componets/LoadingIcon'
import { useNavigate } from 'react-router-dom'

export const ArticlesPageContent = () => {
    const navigate = useNavigate()
    const [articles, setArticles] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const [searchText, setSearchText] = useState("")
    const [isSearching, setIsSearching] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [totalPages, setTotalPages] = useState(10)

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1)
    }
    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1)

    }

    const navigateToEditor = (blogSlug) => {

        navigate(`/articles/${blogSlug}/update/`)
    }
    const fetchArticles = async () => {

        try {
            const response = await api.get(`/blogs/archive/?page=${currentPage}&&page_size=${pageSize}`)

            console.log(response.data);

            if (response.status === 200) {
                setArticles(response.data.results)
                setTotalPages(Math.ceil(response.data.count / pageSize))
                localStorage.setItem('articles', JSON.stringify(response.data))
                setIsLoading(false)
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || "Error getting articles")
            } else {
                setErrorMessage("Unknown error getting articles")
            }
        }
    }
    useEffect(() => {

        fetchArticles()
    }, [currentPage])

    const handleSearch = async () => {
        setIsSearching(true)

        const { results, isTrue, message } = await handleSearchByTitle(searchText)
        setArticles(results)
        setIsSearching(false)
    }

    const handleSearchText = (text) => {
        // if text is empty then set articles to articles from local storage
        setSearchText(text)
        if (text === '') {
            const storedArticles = JSON.parse(localStorage.getItem('articles'))
            if (storedArticles) {
                setArticles(storedArticles)
            }
        }
    }
    return (
        <div className='articles-page'>
            {
                errorMessage && <div className="error-message">{errorMessage}</div>
            }
            <h2>Articles</h2>

            <div className="search-filters">
                <div className="search">
                    <input value={searchText} onChange={(e) => handleSearchText(e.target.value)} type="text" placeholder="Search articles..." />
                    <button onClick={handleSearch} className='outline-button'> {isSearching ? <LoadingIcon size={18} /> : <Search02Icon size={18} />} Search</button>
                </div>
                <div className="filters-create">
                    <a className='button primary-button' href="/articles/create/"> <PlusSignIcon size={18} /> Create article</a>
                </div>
            </div>

            <div className="articles-list">
                {/* table starts */}
                {
                    articles && articles.length > 0
                        ?
                        <table>
                            <thead>
                                <tr>
                                    <th><SquareIcon /> </th>
                                    <th>Featured image</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Views</th>
                                    <th>Comments</th>
                                    <th>Date Published</th>
                                    <th>Clicks Count</th>
                                </tr>
                            </thead>
                            <tbody>

                                {articles.map((article, index) => (
                                    <tr onDoubleClick={() => navigateToEditor(article.slug)} key={index}>

                                        <td><SquareIcon /></td>
                                        <td><img style={{ maxWidth: '80px', height: '52px', borderRadius: '5px', backgroundColor: 'whitesmoke', border: '1px solid grey' }} src={article.featured_image} alt="" /></td>
                                        <td>{article.title.slice(0, 50)}{`${article.title.length > 50 ? '...' : ''}`}</td>
                                        <td className='table-items'>{article.categories?.slice(0, 2).map((cat, index) => (<span key={index}>{cat.name}</span>))}</td>
                                        <td>{article.views}</td>
                                        <td>{article.comments}</td>
                                        <td>{formatDateTime(article.created_at)}</td>
                                        <td className='table-items'><Delete01Icon size={18} color='tomato' /> <PencilEdit01Icon onClick={() => navigateToEditor(article.slug)} color='green' size={18} /> <EyeIcon color='blue' size={18} /></td>
                                    </tr>
                                ))

                                }
                            </tbody>
                        </table>
                        : 'No items found'

                }
                {/* table ends */}
                {/* pagination */}
                <div className="pagination">
                    {/* <input value={pageSize} onChange={e => setPageSize(e.target.value)} type="number" name="" id="" /> */}
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="primary-button small-button">
                        <ArrowLeft01Icon size={18} />
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="primary-button small-button">
                        Next
                        <ArrowRight01Icon size={18} />
                    </button>
                </div>
            </div>
        </div>
    );
};


const ArticlesPage = () => {
    return (
        <PageContainer content={<ArticlesPageContent />} />
    )
}

export default ArticlesPage