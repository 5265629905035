import React, { useEffect, useState, useRef } from 'react'
import PageContainer from '../PageContainer';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import CustomButton from '../../../componets/Button';
import ProfilePictureContainer from './profilePictureContainer';
import CategoriesContainer from './CategoriesContainer';
import AuthorsContainer from './AuthorsContainer';
import { FloppyDiskIcon, RepeatIcon } from 'hugeicons-react';
import { ArticleContainer } from './NewArticle';
import LoadingIcon from '../../../componets/LoadingIcon';


const UpdateArticleContent = () => {
    const [article, setArticle] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedAuthors, setSelectedAuthors] = useState([]);
    const [title, setTitle] = useState("")
    const [featuredImage, setFeaturedImage] = useState({})
    const [slug, setSlug] = useState("");
    const editorRef = useRef(null);
    const [content, setContent] = useState("");
    const [excerpt, setExcerpt] = useState("");


    const { blogSlug } = useParams()
    const articleData = {
        "categories": selectedCategories,
        "authors": selectedAuthors,
        "title": title,
        "slug": slug,
        "content": content,
        "excerpt": excerpt,
        "featured_image": featuredImage.url,
        "tags": [],
        "comments": [],
        "blog_status": "published",

    }

    const handleUpdateArticle = async () => {
        setIsLoading(true);
        console.log("Article data: ", articleData)
        try {
            const response = await api.put(`/dashboard/blogs/${blogSlug}/update/`, articleData)
            if (response.status === 200) {
                setSuccessMessage('Article updated successfully');
                setIsLoading(false);
                setTimeout(() => {
                    window.location.href = `/articles/${blogSlug}/update/`;
                }, 2000);
            }
        } catch (error) {
            console.error(error)
            setIsLoading(false);
            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || 'Error while updating article');
            }
            else {
                setErrorMessage('Unknown error while updating article');
            }
        }
    }

    useEffect(() => {
        const getBlog = async () => {
            setIsLoading(true);
            try {

                const response = await api.get(`/dashboard/blogs/${blogSlug}/`)
                if (response.status === 200) {
                    console.log("data is", response.data)
                    setArticle(response.data)
                    setTitle(response.data.title)
                    setFeaturedImage({
                        url: response.data.featured_image,
                    })
                    setSlug(response.data.slug)
                    setContent(response.data.content)
                    setExcerpt(response.data.excerpt)
                    setSelectedCategories(response.data.categories?.map((cat) => (
                        {
                            value: cat.id,
                            label: cat.name,
                        }
                    )))
                    setSelectedAuthors(response.data.authors?.map((a) => (
                        {
                            value: a.id,
                            label: a.first_name + " " + a.last_name,
                        }
                    )))
                    setIsLoading(false)
                }
            } catch (error) {
                console.error(error)
                setIsLoading(false);
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || 'Error while fetching article');
                }
                else {
                    setErrorMessage('Unknown error while fetching article');
                    console.error(error);
                }
                console.log(error);
            }
        }

        getBlog()
    }, [])
    return (
        <div className="new-article-page">
            <h2>Update article</h2>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            {
                isLoading ?
                    <div className="loading-article">
                        <LoadingIcon size={32} />
                        <span>Loading</span>
                    </div>
                    : <div className='new-article'>
                        <ArticleContainer
                            title={title}
                            setTitle={setTitle}
                            slug={slug}
                            setSlug={setSlug}
                            content={content}
                            setContent={setContent}
                            excerpt={excerpt}
                            setExcerpt={setExcerpt}
                        />
                        <div className="other-content-cards">
                            <div className="buttons">
                                {/* save draft of publish */}
                                <CustomButton
                                    buttonClasses={'primary-button'}
                                    iconClass={<FloppyDiskIcon size={20} />}
                                    buttonText={'Update'}
                                    clickEvent={handleUpdateArticle}
                                />
                                {/* save draft */}
                                <CustomButton
                                    buttonClasses={'outline-button'}
                                    iconClass={<RepeatIcon size={20} />}
                                    buttonText={'Make Draft'}
                                />

                            </div>
                            <ProfilePictureContainer
                                setErrorMessage={setErrorMessage}
                                setSuccessMessage={setSuccessMessage}
                                featuredImage={featuredImage}
                                setFeaturedImage={setFeaturedImage} />
                            <CategoriesContainer
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories} />
                            <AuthorsContainer
                                selectedAuthors={selectedAuthors}
                                setSelectedAuthors={setSelectedAuthors} />
                            {/* <ContributorsContainer /> */}
                            {/* <TagsContainer /> */}

                        </div>
                    </div>
            }
        </div>
    )
}

const UpdateArticlePage = () => {
    return (
        <PageContainer content={<UpdateArticleContent />} />
    )
}

export default UpdateArticlePage
