import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/scss/pageContainer/pageContainer.css'
import { AllBookmarkIcon, ArrowRight01Icon, BloggerIcon, Clock01Icon, Comment01Icon, LeftToRightListNumberIcon, LeftToRightListStarIcon, ListViewIcon, Menu01Icon, Mic01Icon, PlusSignIcon } from 'hugeicons-react'

function PageContainer({ content, docTitle }) {
    const [sideBarCollapsed, setSideBarCollapsed] = useState(false)
    const [user, setUser] = useState(null)
    const collapseSideBar = () => {
        setSideBarCollapsed(!sideBarCollapsed)
    }

    const handleLogout = () => {
        localStorage.removeItem('access')
        window.location.reload()
    }

    useEffect(() => {
        const userData = localStorage.getItem('userData')
        if (userData) {
            const userDataObj = JSON.parse(userData)
            setUser(userDataObj)
        }
    }, [])
    return (
        <div className='page-container'>
            <div className="content-container container">
                <SideBar sideBarCollapsed={sideBarCollapsed} docTitle={docTitle} />
                <div className="page-content">
                    <header className='container'>
                        <Menu01Icon onClick={collapseSideBar} size={26} />
                        <div className="profile-container">
                            <div className="profile-image">
                                <img src="/logo512.png" alt="logo" className='logo' />
                            </div>
                            <div className="profile-details">
                                <h5>{user?.first_name} {user?.last_name}</h5>
                                <small className='logout-button' onClick={handleLogout}>Logout</small>
                            </div>
                        </div>
                    </header>
                    <div className="content">
                        {content}
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
    )
}

export default PageContainer


const Feature = ({ activeFeature, activeLink, handleFeatureClick, featureName, links, handleLinkClick, link, icon }) => {
    return (
        <div className={`feature ${activeFeature === featureName ? 'feature-open' : 'feature'}`}>
            <div onClick={() => handleFeatureClick(featureName)} className="feature-title"><div className="name">{icon}<span>{featureName}</span></div> <ArrowRight01Icon className={`icon ${activeFeature === featureName ? 'rotate' : 'un-rotate'}`} /> </div>
            {
                activeFeature === featureName &&
                <div className="feature-elements">
                    {
                        links && links.map((link, index) => (

                            <Link to={link.link} key={index} onClick={() => handleLinkClick(link.name)} className={`element ${activeLink === link.name ? 'active' : ''}`}>
                                {link.icon}
                                <span className=''>{link.name}</span>
                            </Link>
                        ))
                    }

                </div>
            }
        </div>

    )
}

const SideBar = ({ docTitle, sideBarCollapsed }) => {
    const [activeFeature, setActiveFeature] = useState("Articles")
    const [activeLink, setActiveLink] = useState('')

    const handleFeatureClick = (feature) => {
        setActiveFeature(activeFeature === feature ? '' : feature)
        localStorage.setItem('activeFeature', feature)
    }

    const handleLinkClick = (link) => {
        setActiveLink(activeLink === link ? '' : link)
        localStorage.setItem('activeLink', link)

    }
    useEffect(() => {
        const activeFeatureFromStorage = localStorage.getItem('activeFeature')
        const activeLinkFromStorage = localStorage.getItem('activeLink')
        setActiveFeature(activeFeatureFromStorage || 'Introduction')
        setActiveLink(activeLinkFromStorage || 'What is Q-control')

    }, [])

    return (
        <nav className={`side-bar ${sideBarCollapsed ? 'collapsed' : ''}`}>
            <Link to={'/'} className="header">

                <div className="branding">
                    <img src="/logo512-white.png" alt="logo" className='logo' />
                    <h2 className='title'>KFinancial</h2>
                </div>
            </Link>
            <div className="profile-container">

            </div>
            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Articles'}
                icon={<BloggerIcon />}
                links={[
                    { "name": "Create", link: '/articles/create/', "icon": <PlusSignIcon size={18} /> },
                    { "name": "All Articles", link: '/articles/', "icon": <LeftToRightListNumberIcon size={18} /> },
                    // { "name": "Popular", link: '/articles/popular/', "icon": <LeftToRightListStarIcon size={18} /> },
                    { "name": "Recently Added", link: '/articles/', "icon": <Clock01Icon size={18} /> },
                    { "name": "Comments", link: '/articles/comments/', "icon": <Comment01Icon size={18} /> },
                ]}
            />
            {/* pod casts */}
            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Podcasts'}
                icon={<Mic01Icon />}
                links={[
                    { "name": "All podcasts", link: '/podcasts/', "icon": <LeftToRightListNumberIcon size={18} /> },
                    // { "name": "Popular", link: '/podcasts/popular/', "icon": <LeftToRightListStarIcon size={18} /> },
                    { "name": "Recently Added", link: '/podcasts/', "icon": <Clock01Icon size={18} /> },
                    { "name": "Comments", link: '/podcasts/comments/', "icon": <Comment01Icon size={18} /> },
                ]}
            />

            {/* categories feature */}
            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Categories'}
                icon={<ListViewIcon />}
                links={[
                    // { "name": "Create", link: '/categories/create/', "icon": <PlusSignIcon size={18} /> },
                    { "name": "All Categories", link: '/articles/categories/', "icon": <LeftToRightListNumberIcon size={18} /> },
                    { "name": "Popular", link: '/articles/categories//popular', "icon": <LeftToRightListStarIcon size={18} /> },
                    { "name": "Recently Added", link: '/articles/categories/', "icon": <Clock01Icon size={18} /> },
                ]}
            />

            {/* tags feature */}
            <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Tags'}
                icon={<AllBookmarkIcon />}
                links={[
                    // { "name": "Create", link: /'/tags/create/', "icon": <PlusSignIcon size={18} /> },
                    { "name": "All Tags", link: '/articles/tags/', "icon": <LeftToRightListNumberIcon size={18} /> },
                    { "name": "Popular", link: '/articles/tags/', "icon": <LeftToRightListStarIcon size={18} /> },
                ]}
            />

            {/* authors feature */}
            {/* <Feature
                activeFeature={activeFeature}
                activeLink={activeLink}
                handleFeatureClick={handleFeatureClick}
                handleLinkClick={handleLinkClick}
                featureName={'Authors'}
                icon={<UserListIcon />}
                links={[
                    { "name": "Recently Added", link: '/authors/recent/', "icon": <Clock01Icon size={18} /> },
                    { "name": "Authors", link: '/authors/popular/', "icon": <LeftToRightListStarIcon size={18} /> },
                ]}
            /> */}
        </nav>
    )
}

